<template>
    <div class="row mb-5 align-items-center">
        <div class="col-12">
            <ValidationObserver ref="formModalValidate">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="unit_id" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('unit_id')">
                                <b-form-input v-model="form.unit_id" type="number" step="1">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="faculty_code" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox v-model="form.faculty_code"></faculty-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="program_code" rules="required"
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('program')">
                                <program-selectbox :faculty_code="form.faculty_code"
                                                   v-model="form.program_code"></program-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="scholarship_rate" rules="required"
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('scholarship_rate')">
                                <scholarship-rate-selectbox v-model="form.scholarship_rate">>
                                </scholarship-rate-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>

                </div>
            </ValidationObserver>
        </div>
        <div class="col-12 mt-3 d-flex">
            <b-button @click="updateForm" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("save").toUpper() }}
            </b-button>
        </div>
    </div>
</template>
<script>
//Component
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";

import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ScholarshipRateSelectbox from "@/components/interactive-fields/ScholarshipRateSelectbox";
//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
//Service
import YoksisService from "@/services/YoksisService";


export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ProgramSelectbox,
        FacultySelectbox,
        ScholarshipRateSelectbox
    },
    props:{
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formLoading: false,
            form: {
                unit_id:null,
                faculty_code: null,
                scholarship_rate: null,
                program_code: null,
            }
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            this.formLoading = true;
            YoksisService.get(id)
                .then((response) => {
                    this.form = response.data.data;
                    this.formLoading = false;
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;
                YoksisService.update(this.formId, this.form)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("updateFormSuccess")
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate)
                    }).finally(() => {
                    this.formLoading = false;
                })
            }
        }
    }
}
</script>
